export const order = {
	methods: {
		async makeOrderData() {
			const processFilterData = (filterData, itemOrderDetails) => {
				filterData['channelItemSimpleModel'] = filterData.channelItemModel;
				filterData.channelItemSimpleModel['billingType'] =
					filterData.channelItemModel.channelItemSalesSectionModel.billingType;
				filterData['existingCount'] = this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : filterData.usingCount;
				filterData.finalPrice = 0;

				itemOrderDetails.push(filterData);
			};
			// 추가 or 연장 주문 data make
			// 연장기간이 0 이상
			const current = this.$utils.currentLocalDateTime(); // 현재일자
			const usingMembership = this.$store.getters.getUsingMembership;

			const membershipStatus = usingMembership.status;

			// 1. customer(고객사 정보)
			const customer = usingMembership.customer; // 회사정보
			const representativeCustomer = usingMembership.representativeCustomer; // 대표고객사 정보
			// 2. membership(멤버십 정보)
			const membershipModel = usingMembership; // 고객사 멤버십 정보
			// 3. 회사 정보
			const allCompanyInfo = await this.$store.getters.getNewSiteInfo;
			const companyInfo = {
				corporateName: allCompanyInfo.companyInfo.corporateName,
				businessLicenseNum: allCompanyInfo.companyInfo.businessLicenseNum,
				ceoName: allCompanyInfo.companyInfo.ceoName,
				companyAddress: allCompanyInfo.companyInfo.companyAddress,
				zipCode: allCompanyInfo.companyInfo.companyZipCode,
				repNumber: allCompanyInfo.companyInfo.directPhone,
				industryCategory: allCompanyInfo.companyInfo.industrySector ? allCompanyInfo.companyInfo.industrySector : '',
				businessCondition: allCompanyInfo.companyInfo.businessCondition
					? allCompanyInfo.companyInfo.businessCondition
					: '',
				businessType: allCompanyInfo.companyInfo.businessType ? allCompanyInfo.companyInfo.businessType : '',
				taxInvoiceManager: {
					taxInvoiceManagerName: allCompanyInfo.companyInfo.issuedBillName,
					taxInvoiceEmail: allCompanyInfo.companyInfo.issuedBillEmail,
				},
				businessLicenses: await this.$store.getters.getBusinessRegistration,
			}; // 회사정보
			const dates = await this.$store.getters.getDates;

			// 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 applyDate 는 연장기간과 동일
			const startDate = this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct
				? dates.extensionStartDate
				: dates.addStartDate;

			customer.companyInfo = companyInfo;

			let orderType = ''; // ADD, EXTENSION, ADD_EXTENSION,
			// 7. 총주문금액
			let amount = 0;
			// 8. 사용 인원
			let userCnt = 0;
			// 9. 서비스 타입
			const serviceType = usingMembership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType;
			// 10. 결제 방법
			let payType = '';
			if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
				payType = 'AUTO_CREDITCARD';
			} else if (this.payMethodInfo.payType == 'CREDITCARD') {
				payType = 'CREDITCARD';
			} else if (this.payMethodInfo.payType == 'DEPOSIT') {
				payType = 'TRANSPORT';
			} else if (this.payMethodInfo.payType == 'ACCOUNTTRANSFER') {
				payType = 'REALTIMETRANSPORT';
			}

			// 6. partnerSimpleModel
			const partnerSimpleModel = usingMembership.partnerSimpleModel;

			// 3. orderSummary
			const orderSummary = '';

			let baseChannelOrderInfoModel = null;
			let baseChannelType = ''; // 변경 타입
			let addonChannelType = ''; // 변경 타입
			let orderMonthCount = 0;

			if (this.$store.getters.getBaseChannel.length > 0) {
				let defaultFeeOrderDetail = null;
				let membershipFeeOrderDetail = null;
				let addUserFeeOrderDetails = [];
				// 4. baseChannelOrderInfoModel
				let serviceChange = false; // 옵션에 수정사항(연장/추가) 있는지 여부
				let optionChange = false; // 옵션에 수정사항(연장/추가) 있는지 여부
				const baseChannel = this.$store.getters.getBaseChannel[0];
				let extensionBaseChannels = baseChannel.extension; // 기본서비스 연장 주문
				const extensionBaseChannel = extensionBaseChannels[0];
				let addBaseChannels = baseChannel.add; // 기본서비스 추가 주문
				const addBaseChannel = addBaseChannels[0];
				const options = baseChannel.option;

				if (membershipStatus !== 'USING') {
					baseChannelType = 'EXTENSION';
				} else {
					if (extensionBaseChannels.length > 0) {
						if (addBaseChannels.length > 0) {
							baseChannelType = 'ADD_EXTENSION';
						} else if (options.length > 0) {
							options.forEach((option) => {
								if (option.add.length > 0) {
									baseChannelType = 'ADD_EXTENSION';
								}
							});

							if (baseChannelType !== 'ADD_EXTENSION') {
								baseChannelType = 'EXTENSION';
							}
						} else {
							baseChannelType = 'EXTENSION';
						}
					} else if (addBaseChannels.length > 0) {
						baseChannelType = 'ADD';
					} else if (options.length > 0) {
						options.forEach((option) => {
							if (option.extension.length > 0) {
								if (option.add.length > 0) {
									baseChannelType = 'ADD_EXTENSION';
								} else {
									baseChannelType = 'EXTENSION';
								}
							} else if (option.add.length > 0) {
								baseChannelType = 'ADD';
							}
						});
					}
				}
				if (extensionBaseChannels.length > 0 || addBaseChannels.length > 0) {
					serviceChange = true;
				}
				if (options.length > 0) {
					optionChange = true;
				}
				if (serviceChange) {
					// 기본서비스 정보 setting
					let extension = {
						defaultFee: 0,
						defaultFeePolicyModel: null,
						defaultFeeDiscountInfoModels: [],
						membershipFee: 0,
						membershipFeePolicyModel: null,
						membershipFeeDiscountInfoModels: [],
						addUserFeePolicyModel: null,
						addUserFeeOrderDetails: [],
					};
					let add = {
						defaultFee: 0,
						defaultFeePolicyModel: null,
						defaultFeeDiscountInfoModels: [],
						membershipFee: 0,
						membershipFeePolicyModel: null,
						membershipFeeDiscountInfoModels: [],
						addUserFeePolicyModel: null,
						addUserFeeOrderDetails: [],
					};
					if (extensionBaseChannels.length > 0) {
						const periodPolicyModel = extensionBaseChannel.periodPolicyModel;
						let periodDiscountInfoModel = null;
						const periodPolicyIdx = periodPolicyModel.discountRate.findIndex(
							(obj) => obj.month == extensionBaseChannel.usePeriod,
						);
						if (periodPolicyModel.useLongTermPrepaymentDiscount && periodPolicyIdx > -1) {
							periodDiscountInfoModel = {
								discountName: extensionBaseChannel.usePeriod + '개월 장기선납',
								discountType: 'PERIOD',
								discountRate: parseInt(periodPolicyModel.discountRate[periodPolicyIdx].rate),
							};
						}
						orderMonthCount = extensionBaseChannel.usePeriod;
						if (userCnt < extensionBaseChannel.userCnt) {
							userCnt = extensionBaseChannel.userCnt;
						}
						// 연장 기본서비스 있는 경우에만

						// 1) 장기선납
						if (periodDiscountInfoModel) {
							extension.defaultFeeDiscountInfoModels.push(periodDiscountInfoModel);
						}
						// 설치비
						if (extensionBaseChannel.membershipFeeDiscountPrice) {
							extension.membershipFee = extensionBaseChannel.membershipFeeDiscountPrice;
							extension.membershipFeePolicyModel = extensionBaseChannel.membershipFeePolicyModel;
						}

						// 2) 기본료- 일반 할인
						if (
							extensionBaseChannel.defaultFeeItemModel &&
							(extensionBaseChannel.defaultFeeItemModel.discountPrice > 0 ||
								extensionBaseChannel.defaultFeeItemModel.discountRate > 0)
						) {
							const discountInfoModel = {
								discountName: extensionBaseChannel.defaultFeeItemModel.itemName,
								discountType: 'BASIC',
								discountPrice: extensionBaseChannel.defaultFeeItemModel.discountPrice,
								discountRate: extensionBaseChannel.defaultFeeItemModel.discountRate,
							};

							extension.defaultFeeDiscountInfoModels.push(discountInfoModel);
						}
						// 3) 기본료- 프로모션 할인
						const defaultFeeDiscountIdx = extensionBaseChannel.discountPolicyModels.findIndex(
							(obj) => obj.itemType == 'DEFAULT_FEE' && obj.useExtend,
						);
						if (defaultFeeDiscountIdx > -1) {
							// discountPolicyModel 만들기
							const discountPolicyModel = extensionBaseChannel.discountPolicyModels[defaultFeeDiscountIdx];

							const discountInfoModel = {
								discountName: discountPolicyModel.name,
								discountType: 'PROMOTION',
							};
							if (discountPolicyModel.benefitType == 'RATE') {
								// 율 할인
								discountInfoModel['discountRate'] = discountPolicyModel.num;
							} else if (discountPolicyModel.benefitType == 'AMOUNT') {
								// 금액할인
								discountInfoModel['discountPrice'] = discountPolicyModel.num;
							}

							extension.defaultFeeDiscountInfoModels.push(discountInfoModel);
						}

						extension.defaultFee = extensionBaseChannel.defaultFeeTotDiscountPrice;
						extension.defaultFeePolicyModel = extensionBaseChannel.defaultFeePolicyModel;

						let addUserFeeDiscountInfoModels = [];
						if (periodDiscountInfoModel) {
							addUserFeeDiscountInfoModels.push(periodDiscountInfoModel);
						}
						// 4) 사용자 추가 - 프로모션 할인
						const addUserFeeDiscountIdx = extensionBaseChannel.discountPolicyModels.findIndex(
							(obj) => obj.itemType == 'ADD_USER' && obj.useExtend,
						);
						if (addUserFeeDiscountIdx > -1) {
							// discountPolicyModel 만들기
							const discountPolicyModel = extensionBaseChannel.discountPolicyModels[addUserFeeDiscountIdx];

							const discountInfoModel = {
								discountName: discountPolicyModel.name,
								discountType: 'PROMOTION',
							};
							if (discountPolicyModel.benefitType == 'RATE') {
								// 율 할인
								discountInfoModel['discountRate'] = discountPolicyModel.num;
							} else if (discountPolicyModel.benefitType == 'AMOUNT') {
								// 금액할인
								discountInfoModel['discountPrice'] = discountPolicyModel.num;
							}

							addUserFeeDiscountInfoModels.push(discountInfoModel);
						}
						extensionBaseChannel.addUserDiscountMatrix.forEach((data) => {
							const addUserFeeDiscountInfoModel = [];
							if (addUserFeeDiscountInfoModels.length > 0) {
								// 공통으로 쓰는 할인들
								addUserFeeDiscountInfoModels.forEach((obj) => addUserFeeDiscountInfoModel.push(obj));
							}

							// 3) 사용자 추가 - 기본 할인
							if (extensionBaseChannel.addUserItemModels && extensionBaseChannel.addUserItemModels.length > 0) {
								const discountModel = {
									discountName: '사용자구간 일반할인',
									discountType: 'BASIC',
									discountPrice: 0,
									discountRate: 0,
								};
								//
								if (parseInt(extensionBaseChannel.addUserItemModels[0].discountPrice) > 0) {
									if (data.membershipDiscountPrice) {
										discountModel.discountPrice = data.membershipDiscountPrice;
									}
								} else if (parseInt(extensionBaseChannel.addUserItemModels[0].discountRate) > 0) {
									discountModel.discountRate = parseInt(extensionBaseChannel.addUserItemModels[0].discountRate);
								}

								addUserFeeDiscountInfoModel.push(discountModel);
							}
							extension.addUserFeeOrderDetails.push({
								channelItemSimpleModel: null,
								channelItemType: data.addUserIntervals.channelItemType,
								itemCode: data.code,
								itemName: '사용자추가(' + data.addUserIntervals.min + '~' + data.addUserIntervals.max + ')',
								existingCount: 0,
								addCount: data.discountCnt,
								price: data.price,
								finalPrice: data.totPrice,
								note: null,
								discountInfoModels: addUserFeeDiscountInfoModel,
								status: 'USING',
								itemCancelInfoModel: null,
							});
						});

						extension.addUserFeePolicyModel = extensionBaseChannel.addUserFeePolicyModel;
					}

					if (addBaseChannels.length > 0) {
						if (userCnt == 0) {
							userCnt = addBaseChannel.userCnt;
						}
						if (addBaseChannel.defaultFeeDiscountPrice) {
							add.defaultFee = addBaseChannel.defaultFeeTotDiscountPrice;
							add.defaultFeePolicyModel = addBaseChannel.defaultFeePolicyModel;
						}

						if (addBaseChannel.membershipFeeDiscountPrice) {
							add.membershipFee = addBaseChannel.membershipFeeDiscountPrice;
							add.membershipFeePolicyModel = addBaseChannel.membershipFeePolicyModel;
						}

						if (addBaseChannel.addUserDiscountMatrix) {
							addBaseChannel.addUserDiscountMatrix.forEach((data) =>
								add.addUserFeeOrderDetails.push({
									channelItemSimpleModel: null,
									channelItemType: data.addUserIntervals.channelItemType,
									itemCode: data.code,
									itemName: '사용자추가(' + data.addUserIntervals.min + '~' + data.addUserIntervals.max + ')',
									existingCount: 0,
									addCount: data.discountCnt, // 추가는 addCount
									price: data.price,
									finalPrice: data.totPrice,
									note: null,
									discountInfoModels: [],
									status: 'USING',
									itemCancelInfoModel: null,
								}),
							);

							add.addUserFeePolicyModel = addBaseChannel.addUserFeePolicyModel;
						}
					}
					// 1) 기본료 extension & add 합치기
					defaultFeeOrderDetail = {
						channelItemSimpleModel: null,
						channelItemType: 'DEFAULT_FEE',
						existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : 1, // 기본서비스는 항상 사용중
						addCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 1 : 0, // 추가할때만
						finalPrice: parseFloat(extension.defaultFee) + parseFloat(add.defaultFee),
						note: '기본료',
						useFulfillment: false,
						status: 'USING',
						itemCancelInfoModel: null,
						itemName: '기본료',
					};
					if (extension.defaultFeePolicyModel) {
						defaultFeeOrderDetail['itemCode'] = extension.defaultFeePolicyModel.code;
						defaultFeeOrderDetail['price'] = extension.defaultFeePolicyModel.price;
					} else if (add.defaultFeePolicyModel) {
						defaultFeeOrderDetail['itemCode'] = add.defaultFeePolicyModel.code;
						defaultFeeOrderDetail['price'] = add.defaultFeePolicyModel.price;
					}

					if (extension.discountInfoModels !== null) {
						defaultFeeOrderDetail['discountInfoModels'] = extension.defaultFeeDiscountInfoModels;
					} // 연장만

					// 2) 설치비는 add에만 있음
					if (add.membershipFee > 0) {
						membershipFeeOrderDetail = {
							channelItemSimpleModel: null,
							channelItemType: 'MEMBERSHIP_FEE', //  ☆ 변경예정 add.membershipFeePolicyModel.channelItemType
							itemCode: add.membershipFeePolicyModel.code,
							existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : 1, // 기본서비스는 항상 1
							addCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 1 : 0, // 추가할때만
							price: add.membershipFeePolicyModel.price,
							finalPrice: add.membershipFee,
							note: null,
							//	discountInfoModels: add.membershipFeeDiscountInfoModels,
							status: 'USING',
							itemCancelInfoModel: null,
						};
					} else if (extension.membershipFee > 0) {
						membershipFeeOrderDetail = {
							channelItemSimpleModel: null,
							channelItemType: 'MEMBERSHIP_FEE', //  ☆ 변경예정 add.membershipFeePolicyModel.channelItemType
							itemCode: extension.membershipFeePolicyModel.code,
							existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : 1, // 기본서비스는 항상 1
							addCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 1 : 0, // 추가할때만
							price: extension.membershipFeePolicyModel.price,
							finalPrice: extension.membershipFee,
							note: null,
							//	discountInfoModels: add.membershipFeeDiscountInfoModels,
							status: 'USING',
							itemCancelInfoModel: null,
						};
					}
					// 3) 사용자 추가구간 연장, 추가 합치기
					// existingCount : 기존 사용중이던 사용자 추가 정보 push
					// 연장 : 기존 사용자 수/ 기존 사용자 수 + / 기존 사용자 수 -
					// 추가 : 사용자 추가

					if (addBaseChannel) {
						// 추가 사용자 추가 data 넣기
						add.addUserFeeOrderDetails.forEach((data) => {
							const existingIdx = addUserFeeOrderDetails.findIndex((obj) => obj.itemCode == data.itemCode);
							if (existingIdx < 0) {
								// 없을 때
								addUserFeeOrderDetails.push({
									channelItemSimpleModel: null,
									channelItemType: data.channelItemType,
									itemCode: data.itemCode,
									itemName: data.itemName,
									existingCount: 0,
									addCount: data.addCount,
									price: data.price,
									finalPrice: data.finalPrice,
									note: null,
									discountInfoModels: [],
									status: 'USING',
									itemCancelInfoModel: null,
								});
							} else {
								// 있을 때
								let addUserData = { ...addUserFeeOrderDetails[existingIdx] };
								// 추가
								// 2) 사용자 추가
								// 3) 신규 추가 -> 위 추가로
								addUserData.addCount = data.addCount;
								addUserData.finalPrice += data.finalPrice;
								addUserFeeOrderDetails.splice(existingIdx, 1, addUserData);
							}
						});
					} // 기본서비스 추가/연장 정보 끝

					// 연장 data 넣기
					if (extensionBaseChannel) {
						extension.addUserFeeOrderDetails.forEach((data) => {
							// 모든 count가 data에서는 addCount로 들어옴 -> 적절히 existing, add로 분배
							const existingIdx = addUserFeeOrderDetails.findIndex((obj) => obj.itemCode == data.itemCode);
							if (existingIdx < 0) {
								const usingIdx = usingMembership.baseChannelInfoModel.addUserItemModels.findIndex(
									(obj) => obj.itemCode == data.itemCode,
								);
								let usingCount = data.addCount;
								if (usingIdx >= 0) {
									usingCount = usingMembership.baseChannelInfoModel.addUserItemModels[usingIdx].usingCount;
								}
								const addCount = data.addCount - usingCount;
								// 없을 때(사용자 유지 또는 사용자 감소)
								const detail = {
									channelItemSimpleModel: null,
									channelItemType: data.channelItemType,
									itemCode: data.itemCode,
									itemName: data.itemName,
									existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : usingCount,
									addCount: addCount,
									price: data.price,
									finalPrice: data.finalPrice,
									note: null,
									discountInfoModels: data.discountInfoModels,
									status: 'USING',
									itemCancelInfoModel: null,
								};
								addUserFeeOrderDetails.push(detail);
							} else {
								// 있을 때(기존보다 사용자 추가)
								let addUserData = { ...addUserFeeOrderDetails[existingIdx] };
								addUserData.existingCount =
									this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : data.addCount - addUserData.addCount;
								addUserData.finalPrice += data.finalPrice;
								if (data.discountInfoModels) {
									addUserData.discountInfoModels = data.discountInfoModels;
									addUserData.itemName = data.itemName;
								}
								addUserFeeOrderDetails.splice(existingIdx, 1, addUserData);
							}
						});
					}
					if (baseChannelType !== 'ADD') {
						usingMembership.baseChannelInfoModel.addUserItemModels.forEach((data) => {
							const usingIdx = addUserFeeOrderDetails.findIndex((obj) => obj.itemCode == data.itemCode);
							if (usingIdx >= 0) {
								return;
							}
							addUserFeeOrderDetails.push({
								channelItemSimpleModel: null,
								channelItemType: data.channelItemType,
								itemCode: data.itemCode,
								itemName: data.itemName,
								existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : data.usingCount,
								addCount: 0 - data.usingCount,
								price: data.price,
								finalPrice: data.finalPrice,
								note: null,
								discountInfoModels: [],
								status: 'USING',
								itemCancelInfoModel: null,
							});
						});
					}
				}
				let baseApplyDate = ''; // 서비스 종료일자
				let baseEndDate = ''; // 서비스 종료일자
				if (baseChannelType == 'EXTENSION') {
					baseApplyDate = dates.extensionStartDate;
					baseEndDate = dates.extensionEndDate;
				} else if (baseChannelType == 'ADD_EXTENSION') {
					baseApplyDate = startDate;
					baseEndDate = dates.extensionEndDate;
				} else if (baseChannelType == 'ADD') {
					baseApplyDate = dates.addStartDate;
					baseEndDate = dates.addEndDate;
				}
				// 기본서비스 정보
				// 7. baseChannelOrderInfoModel(BaseChannelOrderInfoModel)
				// baseChannelModel
				const baseChannelModel = usingMembership.baseChannelInfoModel.baseChannelModel;

				// baseChannelBaseInfoModel (개설정보)
				// const baseChannelBaseInfoModel = usingMembership.baseChannelInfoModel.baseChannelBaseInfoModel;
				const representativeGroupware = usingMembership.representativeGroupware
					? usingMembership.representativeGroupware
					: { siteName: '', domain: '', doCluster: null, url: '', virtualDomain: [] };

				//itemOrderDetails(옵션상품리스트)
				let itemOrderDetails = [];
				options.forEach((option) => {
					const filterData = usingMembership.baseChannelInfoModel.usingChannelItemModels.find(
						(usingItem) => usingItem.itemCode === option.code,
					);

					if (filterData !== undefined) {
						processFilterData(filterData, itemOrderDetails);
					}
				});

				if (optionChange) {
					options.forEach((option) => {
						if (option.add.length > 0) {
							option.add.forEach((data) => {
								var text = '';
								// 가상도메인 추가 시
								if (data.itemType == 'VIRTUAL_DOMAIN') {
									data.domainText.forEach((domainText) => {
										representativeGroupware.virtualDomain.push(domainText.text);
										text += domainText.text + ',';
									});
									if (text.length > 0) {
										text = text.slice(0, -1);
									}
								}
								let usingIdx = -1;

								if (itemOrderDetails.length > 0) {
									usingIdx = itemOrderDetails.findIndex((usingOption) => usingOption.itemCode == data.code);
								}
								if (usingIdx > -1) {
									let tempData = { ...itemOrderDetails[usingIdx] };
									tempData.addCount = data.orderCount;
									tempData.finalPrice += data.finalPrice;
									tempData.note = text.length > 0 ? text : data.name;
									tempData.price = data.channelItemSalesSectionModel.price;
									itemOrderDetails.splice(usingIdx, 1, tempData);
								} else {
									itemOrderDetails.push({
										channelItemSimpleModel: data,
										channelItemType: data.itemType,
										itemCode: data.code,
										existingCount: 0,
										addCount: data.orderCount,
										billingType: data.channelItemSalesSectionModel.billingType,
										price: data.channelItemSalesSectionModel.price,
										finalPrice: data.finalPrice,
										note: text.length > 0 ? text : data.name,
										discountInfoModels: [],
										status: 'USING',
										itemCancelInfoModel: null,
									});
								}
							});
						}

						if (option.extension.length > 0) {
							option.extension.forEach((data) => {
								var text = '';
								// 가상도메인 추가 시
								if (data.itemType == 'VIRTUAL_DOMAIN') {
									representativeGroupware.virtualDomain = [];
									data.domainText.forEach((domainText) => {
										representativeGroupware.virtualDomain.push(domainText.text);
										text += domainText.text + ',';
									});
									if (text.length > 0) {
										text = text.slice(0, -1);
									}
								}

								let idx = -1;
								if (itemOrderDetails.length > 0) {
									idx = itemOrderDetails.findIndex((usingOption) => usingOption.itemCode == data.code);
								}
								const discountInfoModel = [];
								// 일반할인
								if (
									data.membershipItemModels &&
									(data.membershipItemModels.discountPrice > 0 || data.membershipItemModels.discountRate > 0)
								) {
									discountInfoModel.push({
										discountName: data.name + ' 일반할인',
										discountType: 'BASIC',
										discountPrice: data.membershipItemModels.discountPrice,
										discountRate: data.membershipItemModels.discountRate,
									});
								}
								if (idx > -1) {
									let tempData = { ...itemOrderDetails[idx] };
									if (!tempData.addCount) {
										tempData['addCount'] = data.orderCount - data.usingCount;
									} else if (tempData.addCount == 0) {
										tempData.addCount = data.orderCount - data.usingCount;
									}
									tempData.finalPrice += data.finalPrice;
									tempData.note = text.length > 0 ? text : data.name;
									tempData.price = data.channelItemSalesSectionModel.price;
									tempData['discountInfoModels'] = discountInfoModel;

									itemOrderDetails.splice(idx, 1, tempData);
								} else {
									itemOrderDetails.push({
										channelItemSimpleModel: data,
										channelItemType: data.itemType,
										itemCode: data.code,
										existingCount: 0,
										addCount: data.orderCount,
										billingType: data.channelItemSalesSectionModel.billingType,
										price: data.channelItemSalesSectionModel.price,
										finalPrice: data.finalPrice,
										note: text.length > 0 ? text : data.name,
										discountInfoModels: discountInfoModel,
										status: 'USING',
										itemCancelInfoModel: null,
									});
								}
							});
						}
					});
				}

				baseChannelOrderInfoModel = {
					baseChannelModel: baseChannelModel,
					baseChannelBaseInfoModel: {
						siteName: representativeGroupware.siteName,
						url: representativeGroupware.url,
						domain: representativeGroupware.domain,
						virtualDomain: representativeGroupware.virtualDomain,
						doCluster: representativeGroupware.doCluster,
					},
					applyDate: baseApplyDate,
					endDate: baseEndDate,
					itemOrderDetails: itemOrderDetails,
					totalOrderQuota: this.$store.getters.getTotalOrderQuota,
				};

				if (baseChannel.baseInfo.membershipFeePolicyModel.usePolicy) {
					baseChannelOrderInfoModel['membershipFeeOrderDetail'] = membershipFeeOrderDetail;
				}

				if (baseChannel.baseInfo.defaultFeePolicyModel.usePolicy) {
					baseChannelOrderInfoModel['defaultFeeOrderDetail'] = defaultFeeOrderDetail;
				}

				if (baseChannel.baseInfo.addUserIntervalPolicyModel.usePolicy) {
					baseChannelOrderInfoModel['addUserFeeOrderDetails'] = addUserFeeOrderDetails;
				}
			} // 기본서비스 정보 end

			let addonChannelOrderInfoModels = [];
			// 부가서비스 정보 start
			if (this.$store.getters.getAddonChannel.length > 0) {
				this.$store.getters.getAddonChannel.forEach((addonChannel) => {
					let defaultFeeOrderDetail = null;
					let membershipFeeOrderDetail = null;
					let addUserFeeOrderDetails = [];
					// 4. baseChannelOrderInfoModel
					let serviceChange = false; // 옵션에 수정사항(연장/추가) 있는지 여부
					let optionChange = false; // 옵션에 수정사항(연장/추가) 있는지 여부
					const extensionAddonChannels = addonChannel.extension; // 기본서비스 연장 주문
					const extensionAddonChannel = addonChannel.extension[0];
					const addAddonChannels = addonChannel.add; // 기본서비스 추가 주문
					const addAddonChannel = addonChannel.add[0];
					const options = addonChannel.option;

					// 각 금액(기본료,설치비,사용자추가) detail
					// defaultFeeOrderDetail(기본료 상세)
					if (membershipStatus !== 'USING') {
						addonChannelType = 'EXTENSION';
					} else {
						if (extensionAddonChannels.length > 0) {
							if (addAddonChannels.length > 0) {
								addonChannelType = 'ADD_EXTENSION';
							} else if (options.length > 0) {
								options.forEach((option) => {
									if (option.add.length > 0) {
										addonChannelType = 'ADD_EXTENSION';
									}
								});
								if (addonChannelType !== 'ADD_EXTENSION') {
									addonChannelType = 'EXTENSION';
								}
							} else {
								addonChannelType = 'EXTENSION';
							}
						} else if (addAddonChannels.length > 0) {
							addonChannelType = 'ADD';
						} else if (options.length > 0) {
							options.forEach((option) => {
								if (option.extension.length > 0) {
									if (option.add.length > 0) {
										addonChannelType = 'ADD_EXTENSION';
									} else {
										addonChannelType = 'EXTENSION';
									}
								} else if (option.add.length > 0) {
									addonChannelType = 'ADD';
								}
							});
						}
					}

					if (extensionAddonChannels.length > 0 || addAddonChannels.length > 0) {
						serviceChange = true;
					}
					if (options.length > 0) {
						optionChange = true;
					}

					const usingIdx = usingMembership.addonChannelInfoModels.findIndex(
						(data) => data.addonChannelModel.code == addonChannel.baseInfo.code,
					); // 사용중인 서비스 인 경우 0 이상
					if (serviceChange) {
						// 기본서비스 정보 setting
						let extension = {
							defaultFee: 0,
							defaultFeePolicyModel: null,
							defaultFeeDiscountInfoModels: [],
							addUserFeePolicyModel: null,
							addUserFeeOrderDetails: [],
						};
						let add = {
							defaultFee: 0,
							defaultFeePolicyModel: null,
							defaultFeeDiscountInfoModels: [],
							membershipFee: 0,
							membershipFeePolicyModel: null,
							membershipFeeDiscountInfoModels: [],
							addUserFeePolicyModel: null,
							addUserFeeOrderDetails: [],
						};
						if (extensionAddonChannels.length > 0) {
							const periodPolicyModel = extensionAddonChannel.periodPolicyModel;
							let periodDiscountInfoModel = null;
							const periodPolicyIdx = periodPolicyModel.discountRate.findIndex(
								(obj) => obj.month == extensionAddonChannel.usePeriod,
							);
							if (periodPolicyModel.useLongTermPrepaymentDiscount && periodPolicyIdx > -1) {
								periodDiscountInfoModel = {
									discountName: extensionAddonChannel.usePeriod + '개월 장기선납',
									discountType: 'PERIOD',
									discountRate: parseInt(periodPolicyModel.discountRate[periodPolicyIdx].rate),
								};
							}

							orderMonthCount = extensionAddonChannel.usePeriod;
							if (userCnt < extensionAddonChannel.userCnt) {
								userCnt = extensionAddonChannel.userCnt;
							}
							// 연장 기본서비스 있는 경우에만
							// 1) 장기선납
							if (periodDiscountInfoModel) {
								extension.defaultFeeDiscountInfoModels.push(periodDiscountInfoModel);
							}

							// 2) 기본료 일반 할인
							if (
								extensionAddonChannel.defaultFeeItemModel &&
								(extensionAddonChannel.defaultFeeItemModel.discountPrice > 0 ||
									extensionAddonChannel.defaultFeeItemModel.discountRate > 0)
							) {
								const discountInfoModel = {
									discountName: extensionAddonChannel.defaultFeeItemModel.itemName,
									discountType: 'BASIC',
									discountPrice: extensionAddonChannel.defaultFeeItemModel.discountPrice,
									discountRate: extensionAddonChannel.defaultFeeItemModel.discountRate,
								};

								extension.defaultFeeDiscountInfoModels.push(discountInfoModel);
							}
							// 3) 기본료 프로모션
							const defaultFeeDiscountIdx = extensionAddonChannel.discountPolicyModels.findIndex(
								(obj) => obj.itemType == 'DEFAULT_FEE' && obj.useExtend,
							);
							if (defaultFeeDiscountIdx > -1) {
								const discountPolicyModel = extensionAddonChannel.discountPolicyModels[defaultFeeDiscountIdx];

								const discountInfoModel = {
									discountName: discountPolicyModel.name,
									discountType: 'PROMOTION',
								};
								if (discountPolicyModel.benefitType == 'RATE') {
									// 율 할인
									discountInfoModel['discountRate'] = discountPolicyModel.num;
								} else if (discountPolicyModel.benefitType == 'AMOUNT') {
									// 금액할인
									discountInfoModel['discountPrice'] = discountPolicyModel.num;
								}

								extension.defaultFeeDiscountInfoModels.push(discountInfoModel);
							}

							extension.defaultFeePolicyModel = extensionAddonChannel.defaultFeePolicyModel;
							if (extensionAddonChannel.defaultFeeTotDiscountPrice) {
								extension.defaultFee = extensionAddonChannel.defaultFeeTotDiscountPrice;
							}

							// 사용자 구간 할인
							const addUserFeeDiscountInfoModels = [];
							if (periodDiscountInfoModel) {
								// 1) 장기선납
								addUserFeeDiscountInfoModels.push(periodDiscountInfoModel);
							}
							const addUserFeeDiscountIdx = extensionAddonChannel.discountPolicyModels.findIndex(
								// 2) 프로모션
								(obj) => obj.itemType == 'ADD_USER' && obj.useExtend,
							);
							if (addUserFeeDiscountIdx > -1) {
								const discountPolicyModel = extensionAddonChannel.discountPolicyModels[addUserFeeDiscountIdx];

								const discountInfoModel = {
									discountName: discountPolicyModel.name,
									discountType: 'PROMOTION',
								};
								if (discountPolicyModel.benefitType == 'RATE') {
									// 율 할인
									discountInfoModel['discountRate'] = discountPolicyModel.num;
								} else if (discountPolicyModel.benefitType == 'AMOUNT') {
									// 금액할인
									discountInfoModel['discountPrice'] = discountPolicyModel.num;
								}

								addUserFeeDiscountInfoModels.push(discountInfoModel);
							}
							// 3) 연장 사용자 추가 구간
							extensionAddonChannel.addUserDiscountMatrix.forEach((data) => {
								const addUserFeeDiscountInfoModel = [];
								if (addUserFeeDiscountInfoModels.length > 0) {
									// 공통으로 쓰는
									addUserFeeDiscountInfoModels.forEach((obj) => addUserFeeDiscountInfoModel.push(obj));
								}
								// 3) 사용자 추가 - 기본 할인
								if (extensionAddonChannel.addUserItemModels && extensionAddonChannel.addUserItemModels.length > 0) {
									const discountModel = {
										discountName: '사용자구간 일반할인',
										discountType: 'BASIC',
										discountPrice: 0,
										discountRate: 0,
									};
									//
									if (parseInt(extensionAddonChannel.addUserItemModels[0].discountPrice) > 0) {
										if (data.membershipDiscountPrice) {
											discountModel.discountPrice = data.membershipDiscountPrice;
										}
									} else if (parseInt(extensionAddonChannel.addUserItemModels[0].discountRate) > 0) {
										discountModel.discountRate = parseInt(extensionAddonChannel.addUserItemModels[0].discountRate);
									}

									addUserFeeDiscountInfoModel.push(discountModel);
								}

								extension.addUserFeeOrderDetails.push({
									channelItemSimpleModel: null,
									channelItemType: data.addUserIntervals.channelItemType,
									itemCode: data.code,
									itemName: '사용자추가(' + data.addUserIntervals.min + '~' + data.addUserIntervals.max + ')',
									existingCount: 0,
									addCount: data.discountCnt,
									price: data.price,
									finalPrice: data.totPrice,
									note: null,
									discountInfoModels: addUserFeeDiscountInfoModel,
									status: 'USING',
									itemCancelInfoModel: null,
								});
							});

							extension.addUserFeePolicyModel = extensionAddonChannel.addUserFeePolicyModel;
						}
						if (addAddonChannels.length > 0) {
							if (userCnt == 0) {
								userCnt = addAddonChannel.userCnt;
							}

							add.defaultFeePolicyModel = addAddonChannel.defaultFeePolicyModel;
							if (addAddonChannel.defaultFeeTotDiscountPrice) {
								add.defaultFee = addAddonChannel.defaultFeeTotDiscountPrice;
							}

							add.membershipFeePolicyModel = addAddonChannel.membershipFeePolicyModel;
							if (addAddonChannel.membershipFeeDiscountPrice) {
								add.membershipFee = addAddonChannel.membershipFeeDiscountPrice;
							}

							if (addAddonChannel.addUserDiscountMatrix) {
								addAddonChannel.addUserDiscountMatrix.forEach((data) =>
									add.addUserFeeOrderDetails.push({
										channelItemSimpleModel: null,
										channelItemType: data.addUserIntervals.channelItemType,
										itemCode: data.code,
										itemName: '사용자추가(' + data.addUserIntervals.min + '~' + data.addUserIntervals.max + ')',
										existingCount: 0,
										addCount: data.discountCnt,
										price: data.price,
										finalPrice: data.totPrice,
										note: null,
										discountInfoModels: [],
										status: 'USING',
										itemCancelInfoModel: null,
									}),
								);

								add.addUserFeePolicyModel = addAddonChannel.addUserFeePolicyModel;
							}
						}

						// 1) 기본료 extension & add 합치기
						defaultFeeOrderDetail = {
							channelItemSimpleModel: null,
							channelItemType: 'DEFAULT_FEE',
							existingCount:
								this.$store.getters.getFixedOrderType === 'CHANGE'
									? 0
									: (extensionAddonChannel && extensionAddonChannel.usingCount == 1) ||
									  (addAddonChannel && addAddonChannel.usingCount == 1)
									? 1
									: 0, // 연장할때만
							addCount: 0, // 추가할때만
							finalPrice: extension.defaultFee + add.defaultFee,
							note: '기본료',
							useFulfillment: false,
							status: 'USING',
							itemCancelInfoModel: null,
						};
						if (add.defaultFeePolicyModel) {
							defaultFeeOrderDetail['itemCode'] = add.defaultFeePolicyModel.code;
							defaultFeeOrderDetail['price'] = add.defaultFeePolicyModel.price;
							if (defaultFeeOrderDetail.existingCount == 0) {
								defaultFeeOrderDetail.addCount = 1;
							}
						} else if (extension.defaultFeePolicyModel) {
							defaultFeeOrderDetail['itemCode'] = extension.defaultFeePolicyModel.code;
							defaultFeeOrderDetail['price'] = extension.defaultFeePolicyModel.price;
							if (defaultFeeOrderDetail.existingCount == 0) {
								defaultFeeOrderDetail.addCount = 1;
							}
						}

						if (extension.defaultFeeDiscountInfoModels) {
							defaultFeeOrderDetail['discountInfoModels'] = extension.defaultFeeDiscountInfoModels;
						}
						// 2) 설치비는 add에만 있음
						if (add.membershipFee > 0) {
							membershipFeeOrderDetail = {
								channelItemSimpleModel: null,
								channelItemType: 'MEMBERSHIP_FEE',
								itemCode: add.membershipFeePolicyModel.code,
								existingCount: 0, // 연장할때만
								addCount: 1, // 추가할때만
								price: add.membershipFeePolicyModel.price,
								finalPrice: add.membershipFee,
								note: null,
								discountInfoModels: [],
								status: 'USING',
								itemCancelInfoModel: null,
							};
							if (usingIdx > -1) {
								membershipFeeOrderDetail.existingCount = this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : 1;
								membershipFeeOrderDetail.addCount = 0;
							}
						}
						// 3) 사용자 추가구간 연장, 추가 합치기
						// 추가 data 넣기
						if (addAddonChannel) {
							add.addUserFeeOrderDetails.forEach((data) => {
								const exsitingIdx = addUserFeeOrderDetails.findIndex((obj) => obj.itemCode == data.itemCode);
								if (exsitingIdx < 0) {
									// 없을 때
									addUserFeeOrderDetails.push({
										channelItemSimpleModel: null,
										channelItemType: data.channelItemType,
										itemCode: data.itemCode,
										itemName: data.itemName,
										existingCount: 0,
										addCount: data.addCount,
										price: data.price,
										finalPrice: data.finalPrice,
										note: null,
										status: 'USING',
										itemCancelInfoModel: null,
									});
								} else {
									// 있는 값
									let addUserData = { ...addUserFeeOrderDetails[exsitingIdx] };
									// 추가
									// 1) 사용자 취소(연장에서 처리)
									// 2) 사용자 추가
									// 3) 신규 추가 -> 위 추가로
									addUserData.addCount = data.addCount;
									addUserData.finalPrice += data.finalPrice;
									addUserFeeOrderDetails.splice(exsitingIdx, 1, addUserData);
								}
							});
						} // 기본서비스 추가/연장 정보 끝

						// 연장 data 넣기
						if (extensionAddonChannel) {
							extension.addUserFeeOrderDetails.forEach((data) => {
								const existingIdx = addUserFeeOrderDetails.findIndex((obj) => obj.itemCode == data.itemCode);
								if (existingIdx < 0) {
									let usingCount = 0;
									if (usingMembership.addonChannelInfoModels[usingIdx] !== undefined) {
										const addonUsingIdx = usingMembership.addonChannelInfoModels[usingIdx].addUserItemModels.findIndex(
											(obj) => obj.itemCode == data.itemCode,
										);
										usingCount = data.addCount;
										if (addonUsingIdx >= 0) {
											usingCount =
												usingMembership.addonChannelInfoModels[usingIdx].addUserItemModels[addonUsingIdx].usingCount;
										}
									}
									// 없을 때(사용자 추가 or 신규+연장)
									addUserFeeOrderDetails.push({
										channelItemSimpleModel: null,
										channelItemType: data.channelItemType,
										itemCode: data.itemCode,
										itemName: data.itemName,
										existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : usingCount,
										addCount: data.addCount - usingCount,
										price: data.price,
										finalPrice: data.finalPrice,
										note: null,
										discountInfoModels: data.discountInfoModels,
										status: 'USING',
										itemCancelInfoModel: null,
									});
								} else {
									// 있는 값
									let addUserData = { ...addUserFeeOrderDetails[existingIdx] };
									addUserData.existingCount =
										this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : data.addCount - addUserData.addCount;
									addUserData.finalPrice += data.finalPrice;
									if (data.discountInfoModels) {
										addUserData.discountInfoModels = data.discountInfoModels;
										addUserData.itemName = data.itemName;
									}
									addUserFeeOrderDetails.splice(existingIdx, 1, addUserData);
								}
							});

							// 구간 삭제 경우 처리
							let preAddCnt = 0;
							if (addUserFeeOrderDetails.length > 0 && extension.addUserFeeOrderDetails.length == 0) {
								preAddCnt = -1;
							}
							for (let i = 0; i < addUserFeeOrderDetails.length; i++) {
								if (
									preAddCnt < 0 &&
									addUserFeeOrderDetails[i].existingCount > 0 &&
									addUserFeeOrderDetails[i].addCount == 0
								) {
									addUserFeeOrderDetails[i].addCount = -1 * addUserFeeOrderDetails[i].existingCount;
								}
								preAddCnt = addUserFeeOrderDetails[i].addCount;
							}
						}
						if (usingIdx >= 0 && addonChannelType !== 'ADD') {
							usingMembership.addonChannelInfoModels[usingIdx].addUserItemModels.forEach((data) => {
								const addonUsingIdx = addUserFeeOrderDetails.findIndex((obj) => obj.itemCode == data.itemCode);
								if (addonUsingIdx >= 0) {
									return;
								}
								addUserFeeOrderDetails.push({
									channelItemSimpleModel: null,
									channelItemType: data.channelItemType,
									itemCode: data.itemCode,
									itemName: data.itemName,
									existingCount: this.$store.getters.getFixedOrderType === 'CHANGE' ? 0 : data.usingCount,
									addCount: 0 - data.usingCount,
									price: data.price,
									finalPrice: data.finalPrice,
									note: null,
									discountInfoModels: [],
									status: 'USING',
									itemCancelInfoModel: null,
								});
							});
						}
					}

					// 부가서비스 정보
					// 7. baseChannelOrderInfoModel(BaseChannelOrderInfoModel)
					// baseChannelModel
					let addonChannelModel = null;
					if (extensionAddonChannel) {
						addonChannelModel = extensionAddonChannel;
					} else if (addAddonChannel) {
						addonChannelModel = addAddonChannel;
					} else if (usingMembership?.addonChannelInfoModels[usingIdx]) {
						addonChannelModel = usingMembership.addonChannelInfoModels[usingIdx].addonChannelModel;
					}

					let addonChannelBaseInfoModel = {};
					// 기존 쓰던거 있으면 넣기
					if (usingIdx > -1) {
						addonChannelBaseInfoModel = usingMembership.addonChannelInfoModels[usingIdx].addonChannelBaseInfoModel;
					} else {
						//신규 개설 시 필요 정보
						const companySiteInfoIdx = allCompanyInfo.companyInfo.addonItem.findIndex(
							(obj) => obj.name == addonChannel.baseInfo.name,
						);
						if (companySiteInfoIdx > -1) {
							addonChannelBaseInfoModel = {
								url: allCompanyInfo.companyInfo.addonItem[companySiteInfoIdx].url,
								adminLoginId: allCompanyInfo.companyInfo.addonItem[companySiteInfoIdx].masterId,
							};
						} // to-do : virtualDomain 추가. 옵션 선택시*/
					}
					//itemOrderDetails(옵션상품리스트)
					let itemOrderDetails = [];
					if (usingIdx > -1) {
						options.forEach((option) => {
							const filterData = usingMembership.addonChannelInfoModels[usingIdx].usingChannelItemModels.find(
								(usingItem) => usingItem.itemCode === option.code,
							);
							if (filterData !== undefined) {
								processFilterData(filterData, itemOrderDetails);
							}
						});
					}

					if (optionChange) {
						options.forEach((option) => {
							if (option.add.length > 0) {
								option.add.forEach((data) => {
									var text = '';
									// 가상도메인 추가 시
									let existingIdx = -1;
									if (itemOrderDetails.length > 0) {
										existingIdx = itemOrderDetails.findIndex((usingOption) => usingOption.itemCode == data.code);
									}

									if (existingIdx > -1) {
										let tempData = { ...itemOrderDetails[existingIdx] };
										tempData.addCount = data.orderCount;
										tempData.finalPrice += data.finalPrice;
										itemOrderDetails.splice(existingIdx, 1, tempData);
									} else {
										itemOrderDetails.push({
											channelItemSimpleModel: data,
											channelItemType: data.itemType,
											itemCode: data.code,
											existingCount: 0,
											addCount: data.orderCount,
											billingType: data.channelItemSalesSectionModel.billingType,
											price: data.channelItemSalesSectionModel.price,
											finalPrice: data.finalPrice,
											note: text.length > 0 ? text : data.name,
											discountInfoModels: [],
											status: 'USING',
											itemCancelInfoModel: null,
										});
									}
								});
							}

							if (option.extension.length > 0) {
								option.extension.forEach((data) => {
									var text = '';
									let existingIdx = -1;
									if (itemOrderDetails.length > 0) {
										existingIdx = itemOrderDetails.findIndex((usingOption) => usingOption.itemCode == data.code);
									}

									if (existingIdx > -1) {
										let tempData = { ...itemOrderDetails[existingIdx] };
										if (!tempData.addCount) {
											tempData['addCount'] = data.orderCount - data.usingCount;
										} else if (tempData.addCount == 0) {
											tempData.addCount = data.orderCount - data.usingCount;
										}
										tempData.finalPrice += data.finalPrice;
										itemOrderDetails.splice(existingIdx, 1, tempData);
									} else {
										itemOrderDetails.push({
											channelItemSimpleModel: data,
											channelItemType: data.itemType,
											itemCode: data.code,
											existingCount: 0,
											addCount: data.orderCount,
											billingType: data.channelItemSalesSectionModel.billingType,
											price: data.channelItemSalesSectionModel.price,
											finalPrice: data.finalPrice,
											note: text.length > 0 ? text : data.name,
											discountInfoModels: [],
											status: 'USING',
											itemCancelInfoModel: null,
										});
									}
								});
							}
						});
					}

					let addonApplyDate = ''; // 서비스 종료일자
					let addonEndDate = ''; // 서비스 종료일자
					if (addonChannelType == 'EXTENSION') {
						addonApplyDate = dates.extensionStartDate;
						addonEndDate = dates.extensionEndDate;
					} else if (addonChannelType == 'ADD_EXTENSION') {
						addonApplyDate = startDate;
						addonEndDate = dates.extensionEndDate;
					} else if (addonChannelType == 'ADD') {
						addonApplyDate = dates.addStartDate;
						addonEndDate = dates.addEndDate;
					}

					let freeTrialOrderType = 'NONE';
					if (addonChannel.freeTrialPolicyModel !== undefined && addonChannel.baseInfo.usingCount == 0) {
						if (addonChannel.freeTrialPolicyModel.usePolicy) {
							freeTrialOrderType = 'FREE_TRIAL';
							addonEndDate = new Date(addonApplyDate);
							addonEndDate.setMonth(current.getMonth() + addonChannel.freeTrialPolicyModel.usagePeriod);
							addonEndDate.setDate(addonEndDate.getDate() - 1);
						}
					}

					const addonChannelOrderInfoModel = {
						addonChannelModel: addonChannelModel,
						addonChannelBaseInfoModel: addonChannelBaseInfoModel,
						applyDate: addonApplyDate,
						endDate: addonEndDate,
						itemOrderDetails: itemOrderDetails,
						freeTrialOrderType: freeTrialOrderType,
						new:
							(extensionAddonChannel && extensionAddonChannel.usingCount == 1) ||
							(addAddonChannel && addAddonChannel.usingCount == 1) ||
							(!extensionAddonChannel && !addAddonChannel)
								? false
								: true,
					};

					if (addonChannel.baseInfo.membershipFeePolicyModel.usePolicy) {
						addonChannelOrderInfoModel['membershipFeeOrderDetail'] = membershipFeeOrderDetail;
					}

					if (addonChannel.baseInfo.defaultFeePolicyModel.usePolicy) {
						addonChannelOrderInfoModel['defaultFeeOrderDetail'] = defaultFeeOrderDetail;
					}

					if (addonChannel.baseInfo.addUserIntervalPolicyModel.usePolicy) {
						addonChannelOrderInfoModel['addUserFeeOrderDetails'] = addUserFeeOrderDetails;
					}

					addonChannelOrderInfoModels.push(addonChannelOrderInfoModel);
				});
			}
			if (baseChannelType == 'ADD_EXTENSION' || addonChannelType == 'ADD_EXTENSION') {
				orderType = 'ADD_EXTENSION';
			} else if (
				(baseChannelType == 'ADD' && addonChannelType == 'EXTENSION') ||
				(baseChannelType == 'EXTENSION' && addonChannelType == 'ADD')
			) {
				orderType = 'ADD_EXTENSION';
			} else if (baseChannelType == 'ADD' || addonChannelType == 'ADD') {
				orderType = 'ADD';
			} else if (baseChannelType == 'EXTENSION' || addonChannelType == 'EXTENSION') {
				orderType = 'EXTENSION';
			}
			let applyDate = '';
			let endDate = '';
			if (orderType == 'EXTENSION') {
				applyDate = dates.extensionStartDate;
				endDate = dates.extensionEndDate;
			} else if (orderType == 'ADD_EXTENSION') {
				applyDate = startDate;
				endDate = dates.extensionEndDate;
			} else if (orderType == 'ADD') {
				applyDate = dates.addStartDate;
				endDate = dates.addEndDate;
			}
			// 2. baseInfoModel
			const baseInfoModel = {
				orderType: this.$store.getters.getFixedOrderType === 'CHANGE' ? 'CHANGE' : orderType,
				orderMonthCount: orderMonthCount,
				orderDate: this.$utils.currentLocalDateTime(), //오늘
				// 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 applyDate 는 연장기간과 동일
				applyDate:
					orderType == 'EXTENSION' ||
					(orderType == 'ADD_EXTENSION' && this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct)
						? applyDate
						: current, //오늘
				orderEndDate: endDate,
				salesRepresentative: null,
				hasEndDate: this.$store.getters.getNewMembershipChargeType === 'FREE' ? false : true,
			};
			const totPrices = this.$store.getters.getAddExtensionPrices;
			const param = {
				id: null,
				createdAt: null,
				updatedAt: null,
				createdBy: null,
				updatedBy: null,
				customer: customer,
				representativeCustomer: representativeCustomer,
				membershipModel: membershipModel,
				baseInfoModel: baseInfoModel,
				orderSummary: orderSummary,
				baseChannelOrderInfoModel: baseChannelOrderInfoModel,
				addonChannelOrderInfoModels: addonChannelOrderInfoModels,
				partnerSimpleModel: partnerSimpleModel,
				userCnt: userCnt,
				amount: totPrices.finalPayPrice,
				serviceType: serviceType,
				payType: payType,
				totalPrice: totPrices.finalPayPrice,
				paymentDate: null,
				issuedBill: this.payMethodInfo.issuedBill,
				emailRecipientModel: {
					email: this.payMethodInfo.emailRecipientEmail,
					phone: this.payMethodInfo.emailRecipientPhone,
				},
				carryOverAmount: this.$store.getters.getRefundableAmount,
			};
			return param;
		},
	},
};
